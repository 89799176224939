<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <main class="space-y-5">
        <section class="grid grid-cols-1 md:grid-cols-1 gap-6">
          <ValidationObserver  v-slot="{ invalid }" tag="div" class="box flex flex-col">
            <div>
              <h1 class="title">部門名稱 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="部門名稱" placeholder="請輸入部門名稱" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="name" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div v-if="id != 0">
              <h1 class="title">權限</h1>
              <div v-for="func in funcs" :key="func.id">
                <vs-checkbox v-if='func.indeterminate' class="checkbox_section" indeterminate @change="checkAllHandleClick(func.id)" v-model="func.ishas" :val="func.id">
                  <span class="ml-2">{{ func.name }}</span>
                </vs-checkbox>
                <vs-checkbox v-else class="checkbox_section" @change="checkAllHandleClick(func.id)" v-model="func.ishas" :val="func.id">
                  <span class="ml-2">{{ func.name }}</span>
                </vs-checkbox>
                <hr class="h-2" />
                <div v-for="children in func.children" :key="children.id" class="ml-7">
                  <vs-checkbox class="checkbox_section" @change="checkHandleClick(func.id)" v-model="children.ishas" :val="children.id"><span class="ml-2">{{ children.name }}</span></vs-checkbox>
                </div>
                <br />
              </div>
            </div>
            <div class="flex justify-between">
              <vs-button size="large" color="danger" border @click="delete_dialog = true" v-if="id != 0 && id != 1">
                <span class="font-medium">刪除</span>
              </vs-button>
              <div class="w-4"></div>
              <vs-button size="large" ref="saveBtn" :disabled="invalid" @click="saveBtn(invalid)">
                <span class="font-medium">儲存</span>
              </vs-button>
            </div>
            
          </ValidationObserver>
        </section>
      </main>
    </div>

    <!-- 刪除會員 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除{{ title }}資料？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的{{ title }}資料無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteBtn" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'role',
  data() {
    return {
      title: '部門',
      delete_dialog: false,
      id: 0,
      name: '',
      funcs: [
        // {
        //     "id": 23,
        //     "name": "平台管理",
        //     "ishas": false,
        //     "indeterminate": false,
        //     "children": [
        //         {
        //             "id": 24,
        //             "name": "首頁設定",
        //             "ishas": false,
        //         },
        //         {
        //             "id": 25,
        //             "name": "創立目的",
        //             "ishas": false,
        //         },
        //         {
        //             "id": 26,
        //             "name": "團隊介紹",
        //             "ishas": false,
        //         },
        //         {
        //             "id": 27,
        //             "name": "什麼是二房東",
        //             "ishas": false,
        //         }
        //     ]
        // }
      ]
    }
  },
  created() {
    this.init()
  },

  methods: {
    init() {
      this.id = this.$route.params.id
      if (this.id) {
        this.$route.meta.title = `編輯${this.title}資料`
        this.loadData()
      }else{
        this.id = 0
        this.$route.meta.title = `新增${this.title}資料`
      }
    },
    loadData() {
      this.$axios({
        url: `backstage/roles/role/${this.id}`,
        method: 'get',
      }).then((res) => {
        // console.log(res)
        if (res.data.status) {
          this.is_API_loaded = true
          this.name = res.data.data.role.name
          this.funcs = res.data.data.funcs
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    saveBtn(invalid) {
      if (this.id) {
        this.saveSubmit(`backstage/roles/role/${this.id}`, 'put', invalid);
      }else{
        this.saveSubmit('backstage/roles/role', 'post', invalid);
      }
    },
    saveSubmit(url, method, invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      console.log(this.$data)
      this.$axios({
        url: url,
        method: method,
        data: {
          name: this.name,
          funcs: this.funcs,
        },
      }).then((res) => {
        loading.close()
        if(res){
           if (res.data.status) {
            this.$myVS.openNoti(res.data.message, '', undefined)
            if(this.id == 0){
              this.$router.replace({
                name: 'edit-role',
                params: {
                  id: res.data.data.id,
                },
                query: this.$route.query
              })
              setTimeout(() => {
                this.init()
              }, 800);
            }else{
              this.$router.replace({
                name: 'roles',
                query: this.$route.query
              })
            }
          } else {
            this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
          }
        }
      })
    },
    deleteBtn() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: `backstage/roles/role/${this.id}`,
        method: 'delete',
      }).then((res) => {
        loading.close() // 關閉按鈕loading
        if (res.data.status) {
          this.$myVS.openNoti(res.data.message, '', undefined)
          this.$router.replace({
            name: 'roles',
            query: this.$route.query
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    checkHandleClick(id) {
      this.funcs.forEach(func => {
        if(func.id === id){
          // 判斷children是否有true
          let childIsHas = false
          let indeterminate = false
          func.children.forEach(child => {
            if(child.ishas){
              childIsHas = true
            }else{
              indeterminate = true
            }
          })
          if(childIsHas) func.ishas = true
          else func.ishas = false
          if(indeterminate) func.indeterminate = true
          else{
            func.indeterminate = false
            this.$nextTick(() => {
              func.ishas = true
            })
          } 
        }
      })
    },
    checkAllHandleClick(id) {
      this.funcs.forEach(func => {
        if(func.id === id && func.ishas){
          func.indeterminate = false
          func.children.forEach(child => {
            child.ishas = true
          })
        }else if(func.id === id && !func.ishas){
          func.indeterminate = false
          func.children.forEach(child => {
            child.ishas = false
          })
        }
      })
    }
  },
}
</script>

<style scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}
.title span{
  color: #FF0000;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

::v-deep .vs-input__icon {
  left: 1px;
}
</style>
